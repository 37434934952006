<template>
  <div class="cont-warp">
    <pub-header></pub-header>
    <div>
      <img src="../../../assets/images/securityTrain/h5/cdse/pic01.png"/>
      <img src="../../../assets/images/securityTrain/h5/cdse/pic02.png"/>
      <!--      <img src="../../../assets/images/securityTrain/h5/cdse/pic03.png"/>-->
      <erweima></erweima>
    </div>
    <div style="height: 60px"></div>
    <pub-footer></pub-footer>
  </div>
</template>

<script>
import PubFooter from "./pub/pubFooter";
import PubHeader from "./pub/pubHeader";
import Erweima from './pub/erweima'

export default {
  name: '',
  components: {
    PubFooter,
    PubHeader,
    Erweima
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
  },
  mounted() {
    if (this._isMobile()) {
      this.$router.replace('/h5cdsePage');
    } else {
      this.$router.replace('/cdsePage');
    }
  },
}
</script>
<style lang="less" scoped>
.cont-warp {
  // width: 1200px;
  // margin: 0 auto;
  img {
    max-width: 100%;
    margin: 0px 0 30px 0;
  }
}
</style>
